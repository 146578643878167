<template>
    <div class="sms_template_list">
        <el-card>
            <nav class="input" style="width: auto">
                <el-input v-model="search.keyword" size="medium" placeholder="请输入企业名称" />
            </nav>
          <nav class="input" style="width: auto">
            <el-select v-model="search.categoryId" size="medium" placeholder="请选择企业分类">
              <el-option
                  v-for="item in voteCategoryList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </nav>
            <nav class="input" style="width: 150px">
                <el-button size="small" @click="reset">重置</el-button>
                <el-button type="primary" size="small" @click="$refs.table.reload()">{{ "搜索" }}</el-button>
            </nav>
            <nav class="input" style="width: auto">
              <el-button type="primary" size="small" @click="addEnterpriseDialogVisible=true">添加企业</el-button>
            </nav>
        </el-card>
        <el-card style="margin-top: 10px">
            <z-table ref="table" v-model="list" @query="queryList" fullscreenLoading>
                <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
                  <el-table-column prop="id" label="ID"> </el-table-column>
                  <el-table-column prop="categoryName" label="企业分类"> </el-table-column>
                    <el-table-column prop="enterpriseId" label="企业ID"> </el-table-column>
                    <el-table-column prop="enterpriseName" label="企业名称"> </el-table-column>
                    <el-table-column prop="enterpriseLogo" label="企业LOGO">
                      <template #default="{row}">
                        <img class="logo-img" :src="row.enterpriseLogo">
                      </template>
                    </el-table-column>
                    <el-table-column prop="voteNum" label="票数">
                        <template #default="{row}">
                            <Remark :row="row" url="/admin/Gicc/editVoteNum" @getlist="getlist" rowKey="voteNum"></Remark>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="{ row }">
<!--                            <el-button @click="onEdit(row)" type="text" icon="el-icon-edit" size="mini">编辑</el-button>-->
                            <el-button
                                @click="onDelete(row)"
                                type="text"
                                icon="el-icon-delete"
                                size="mini"
                                style="color: red;"
                                >删除</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </z-table>
        </el-card>
      <el-dialog
          title="添加企业"
          :visible.sync="addEnterpriseDialogVisible"
          width="30%"
          center>
        <el-select v-model="addForm.categoryId" size="medium" placeholder="请选择企业分类">
          <el-option
              v-for="item in voteCategoryList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <div class="input-detail" >
          <input placeholder="输入企业名称模糊查询" class="clear-input"
                 v-model="state"  type="text" name="" id=""
                 @input="querySearchAsync" @blur="toClose" />
          <div class="theSearchBox" v-show="searchInput">
            <div class="item flex-align" v-for="(item, index) in searchList" :key="item.id">
              <img :src="item.logo" alt="" />
              <div class="desc clamp-1">{{ item.name }}</div>
              <div class="btn" @click="add(item)">添加</div>
            </div>
            <div class="foot">
              <div class="add-text">搜索不到您要添加的企业，请联系客服添加</div>
              <img src="https://static-cdn.yfchuhai.com/www.yfchuhai.com/static/pc/img/index/grounp/anan_code.jpg" alt="" />
              <!-- <span style="flex: 1; font-size: 18px; color: #333333">搜索不到您要添加的企业</span>
              <div class="undata" @click.stop="manuallyAdd">手动添加</div> -->
            </div>
          </div>
        </div>
        <div class="list flex-sh">
                         <div class="list-item flex-center" v-for="(item, index) in enterpriseList" :key="index">
                        <img :src="item.logo" alt="" />
                        <div class="desc">{{ item.name }}</div>
                        <div class="box flex-center">
                          <div class="btn" @click="onDel(item)">删除</div>
                        </div>
                      </div>
        </div>
        <span slot="footer" class="dialog-footer">
    <el-button @click="addEnterpriseDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="addEnterprise">确 定</el-button>
  </span>
      </el-dialog>
    </div>
</template>

<script>
import list from "/src/mixin/list.js";
import {debounce} from "@/utils/methods";
import Img from "@/views/enterprise/enterprise_cert_list/img.vue";
export default {
  components: {Img},
    mixins: [list],
    data() {
        return {
            search: {
                keyword: "",
              categoryId:""
            },
          addForm:{
            categoryId:"1",
            enterpriseId:""
          },
          addEnterpriseDialogVisible:false,
          state:"",
          searchList:[],
          searchInput:false,
          voteCategoryList:[],
          enterpriseList:[]
        };
    },
    activated() {
        console.log(1);
    },
  created() {
    this.getVoteCategoryList()
  },

  methods: {
      querySearchAsync: debounce(async function () {
        this.state=this.state.trim()

        const { data } = await this.$http.get(
            '/api/Enterprise/getList' ,{params:{
                page:'1',
                pageSize:'5',
                type:'-1',
                business:'-1',
                serverArea:'-1',
                keyword:this.state}}
        );
        console.log(data);
        this.searchList = data.data.list;
        this.searchInput = true;
      }),
    async onDel(row) {
      this.enterpriseList=this.enterpriseList.filter(item=>item.id!=row.id)
    },
    async add(row) {
      if(this.enterpriseList.length<5){
        const i = this.enterpriseList.filter(item=>item.id ==row.id)
        if(i.length){
          this.$alert('该企业选中，请添加其他企业', '提示', {
            confirmButtonText: '确定',
            callback: action => {
            }
          });
        }else {
          this.enterpriseList=[...this.enterpriseList,row]
        }
        if (row) {
          this.searchInput=false
          this.state=''
        }
      }else {
        this.$alert('最多添加5个企业', '提示', {
          confirmButtonText: '确定',
          callback: action => {
          }
        });
      }
    },
      toClose() {
        setTimeout(() => {
          this.searchInput = false;
        }, 300);
      },
      async getVoteCategoryList(){
        let { data: res } = await this.$http.get("/admin/Gicc/getVoteCategoryList");
        // this.$message.success(res.message);
        if(res.errorCode == 200){
          this.voteCategoryList=res.data.list
        }
      },
    async addEnterprise(){
      if(this.enterpriseList.length){
        this.addForm.enterpriseId = this.enterpriseList.map(item=>item.id).join(',')
        let { data: res } = await this.$http.get("/admin/Gicc/addVoteEnterprise", {
          params: { ...this.addForm },
        });
        // this.$message.success(res.message);
        if(res.errorCode == 200){
          this.addEnterpriseDialogVisible=false
          this.addForm={
            categoryId:"1",
            enterpriseId:""
          }
          this.enterpriseList=[]
          this.getlist()
        }
      }else {
        this.$alert('暂未选择企业，请选择企业后添加', '提示', {
          confirmButtonText: '确定',
          callback: action => {
          }
        });
      }

    },

        getlist() {
            this.$refs.table.refresh();
            this.getVoteCategoryList();
            console.log(this.search)
          console.log(this.voteCategoryList)
        },

        async queryList(val) {
            let { data: res } = await this.$http.get("/admin/Gicc/getVoteList", {
                params: { ...val, ...this.search },
            });
            if (res.errorCode == 200) {
                // this.$message.success(res.message);
                this.$refs.table.complete(res.data);
            }
        },
        onEdit(row) {
            this.$router.push({ name: "sms_template_list_add", query: { id: row.id } });
        },
        async onDelete(row) {
            await this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            });
            let { data: res } = await this.$http.post("/admin/Gicc/delVoteEnterprise", row);
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
            }
        },
        Ftag(val) {
            if (val == 0) {
                return "待审核";
            }
            if (val == 1) {
                return "审核通过";
            }
            if (val == 2) {
                return "审核不通过";
            }
            return "";
        },
    },
};
</script>

<style lang="less" scoped>
.logo-img{
  width: 60px;
  height: 60px;
}
.input-detail {
  margin-top: 20px;
  width: 494px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  padding-left: 16px;
  position: relative;
  margin-bottom: 30px;
  z-index: 2;

  input {
    &::placeholder {
      color: #9EA2B5;
    }
  }

   input {
       width: 100%;
       height: 100%;
       border: 0;
       outline: 0;
       background-color: transparent;
       &::-webkit-input-placeholder {
           font-size: 14px;
           font-weight: 500;
           color: #999999;
       }
   }
}
:deep(.el-card .el-card__body){
  padding: 0 30px;
}
.input-tip {
  color: #9EA2B5;
  font-size: 14px;
  margin-top: 17px;
  line-height: 1.5;
  span{
    color: #E2241B;
  }
}
.theSearchBox {
  width: 368px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 20px 25px;
  position: absolute;
  top: 47px;
  left: 0px;

  .undata {
    width: 89px;
    height: 34px;
    background: #0a7ff2;
    border-radius: 17px;
    color: #fff;
    line-height: 34px;
    text-align: center;
    cursor: pointer;
  }

  .foot {
    margin-top: 20px;
    text-align: center;

    .add-text {
      color: #333333;
      font-size: 16px;
    }

    img {
      width: 165px;
      height: 165px;
      margin-top: 30px;
    }


  }
  .item {
    // margin-bottom: 30px;
    padding: 15px 0;
    border-bottom: 1px solid rgba(229, 229, 229, 0.6);

    &:first-child {
      padding-top: 0;
    }

    img {
      width: 54px;
      height: 54px;
      border-radius: 10px;
      flex-shrink: 0;
    }

    .desc {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      margin-left: 22px;
      width: 154px;
    }

    .btn {
      width: 90px;
      height: 35px;
      border: 1px solid #0a7ff2;
      border-radius: 17px;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: #0a7ff2;
      line-height: 35px;
      margin-left: auto;
      flex-shrink: 0;
    }
  }
}
.list {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 10px;
   .list-item {
       flex-direction: column;
       width: 100px;
       height: 100px;
       background: #ffffff;
       border: 1px solid #f2f2f2;
       border-radius: 10px;
       position: relative;
       overflow: hidden;
       margin-right: 10px;
       margin-bottom: 10px;
       padding: 10px;
     display: flex;

       &:hover {
           .box {
               display: flex;
           }
       }

       >img {
           width: 80px;
           height: 80px;
           border-radius: 6px;
           margin-bottom: 5px;
       }

       .desc {
         width: 90px;
           font-size: 12px;
           font-weight: 400;
           color: #333333;
         overflow: hidden;
         white-space: nowrap;
         text-overflow:ellipsis;
         text-align: center;
       }

       .box {
           display: none;
           position: absolute;
           top: 0;
           left: 0;
           width: 100%;
           height: 100%;
           background: rgba(0, 0, 0, 0.6);

           .btn {
               width: 100px;
               height: 42px;
               line-height: 42px;
               text-align: center;
               background: #ffffff;
               border-radius: 21px;
               color: #0a7ff2;
               font-size: 18px;
           }
       }
   }


}
</style>
